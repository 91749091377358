import React, { useState } from "react";
import Pricing from "../Pricing/Pricing";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/icons/Filters/arrow up.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleStateChange } from "../../../../data/slices/filtersSlice";
import DailyRentPrices from "../DailyRentPricesFilters/DailyRentPrices";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import Slider from "../Inputs/Slider/Slider";
import { InputNumber } from "antd";
import Specifications from "../Specifications/Specifications";

function AuctionSpecification() {
  const [open, setOpen] = useState(true);
  const [day, setDay] = useState(3);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { specifications, default_values, currency_icon } = useSelector(
    (state) => state.properties
  );

  const properties = useSelector((state) => state.properties);



  const {
    minprice,
    maxprice,
    price_per_meter_monthly_range,
    price_per_meter_yearly_range,
    price_per_meter,
    toll_price_per_meter_range,
    sell_available,
    toll_price,
    toll_available,
    payment_method,
    last_toll_price_range,
    last_toll_price_per_meter_range,
    days_since,
    auction_type,
  } = useSelector((state) => state.filters);
  const filters = useSelector((state) => state.filters);

  const handleBtnClick = (target, event) => {
    const { name, checked } = event.target;

    const result = () => {
      // If both checkboxes are checked
      if (name === "online" && checked && auction_type === "offline") {
        return "online,offline";
      } else if (name === "offline" && checked && auction_type === "online") {
        return "online,offline";
      }
      // If only one checkbox is checked, set state accordingly
      else if (checked) {
        return name;
      }
      // If no checkbox is checked, set state to null
      else if (!checked) {
        // If the unchecked checkbox is the one that is present in the state, set the state to null
        if (auction_type === name) {
          return null;
        }
      }
    };

    dispatch(
      handleStateChange({
        target,
        value: result(),
      })
    );
  };

  const handleClick = (inc) => {
    if (inc) {
      dispatch(
        handleStateChange({
          target: "days_since",
          value: days_since + 1,
        })
      );
    } else {
      dispatch(
        handleStateChange({
          target: "days_since",
          value: days_since - 1,
        })
      );
    }
  };



  return (
    <>
      <button className="collapse-btn" onClick={() => setOpen(!open)}>
        {t("filters.Specifications_filters")}
        <ArrowUpIcon className={!open ? "flip-arrow" : ""} />
      </button>
      {open ? (
        <div className="filters-content-container">
          <Specifications />
        </div>
      ) : null}
    </>
  );
}



export default AuctionSpecification