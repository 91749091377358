import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as RealIcon } from "../../assets/icons/sidebar/real.svg";
import { ReactComponent as PropIcon } from "../../assets/icons/sidebar/allProperties.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/sidebar/email.svg";
import { ReactComponent as CompIcon } from "../../assets/icons/sidebar/company.svg";
import { ReactComponent as ManageIcon } from "../../assets/icons/sidebar/manageProp.svg";
import { ReactComponent as BunIcon } from "../../assets/icons/sidebar/propIcon.svg";
import { ReactComponent as FavIcon } from "../../assets/icons/sidebar/fav.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/sidebar/Profile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/sidebar/logout.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/sidebar/loginIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../data/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { logout } from "../../data/slices/authSlice";

const NavigationLink = ({ icon = null, route = "/", children }) => {
  const dispatch = useDispatch();

  return (
    <>
      <NavLink
        to={route}
        className="navigation_link"
        onClick={() => dispatch(closeSidebar())}
      >
        {icon}
        <span className="navigation_link__label">{children}</span>
      </NavLink>
      <div className="divider"></div>
    </>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  return (
    <div className="navigation__container">
      <NavigationLink route="/" icon={<HomeIcon />}>
        {t("header.home")}
      </NavigationLink>
      <NavigationLink route="/goods" icon={<PropIcon />}>
        {t("header.goods")}
      </NavigationLink>
      <NavigationLink route="/real-estate" icon={<PropIcon />}>
        {t("header.all_properties")}
      </NavigationLink>
      <NavigationLink route="/brokers" icon={<RealIcon />}>
        {t("sidebar.Real_estate_brokers")}
      </NavigationLink>
      <NavigationLink route="/contact" icon={<EmailIcon />}>
        {t("sidebar.Contact_us")}
      </NavigationLink>
      <button
        className="submit_prop"
        onClick={() => {
          navigate("/property");
          dispatch(closeSidebar());
        }}
      >
        <BunIcon /> {t("header.submit_property")}
      </button>
      <div className="divider"></div>
      {!isAuthenticated ? (
        <button
          className="login_sidebar"
          onClick={() => {
            navigate("/auth/login");
            dispatch(closeSidebar());
          }}
        >
          <LoginIcon /> {t("header.login")}
        </button>
      ) : (
        <>
          {user?.usertype != "selling_agent" ? (
            <NavigationLink route="/profile/user" icon={<ProfileIcon />}>
              {t("sidebar.personal_information")}
            </NavigationLink>
          ) : (
            <NavigationLink route="/profile/org" icon={<CompIcon />}>
              {t("sidebar.Company_information")}
            </NavigationLink>
          )}
          {user?.usertype != "selling_agent" ? (
            <NavigationLink route="/management-sale-org" icon={<ManageIcon />}>
              {t("sidebar.Manage_properties")}
            </NavigationLink>
          ) : (
            <NavigationLink route="/management-sale-org" icon={<ManageIcon />}>
              {t("sidebar.manage_properties_company")}
            </NavigationLink>
          )}
          <NavigationLink route="/favorites" icon={<FavIcon />}>
            {t("sidebar.Favorites")}
          </NavigationLink>
          <button className="logout_sidebar" onClick={() => dispatch(logout())}>
            <LogoutIcon /> {t("sidebar.logout")}
          </button>
        </>
      )}
    </div>
  );
};

export default Navigation;
