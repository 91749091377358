import { useTranslation } from "react-i18next";
import { Drawer, Modal, Radio, Select, Spin, message } from "antd";
import { useState } from "react";
import Filters from "../GoodsFilter/GoodsFilter";
import { ReactComponent as SearchIcon } from "../../assets/icons/property/search.svg";
import { ReactComponent as Frame1Icon } from "../../assets/icons/ListSale/menu2.svg";
import { ReactComponent as Frame2Icon } from "../../assets/icons/ListSale/menu1.svg";
import Card from "../GoodsList/components/Card/Card";
import { useDispatch, useSelector } from "react-redux";
import { openFilter } from "../../data/slices/settingsSlice";
import { useEffect } from "react";
import { ReactComponent as OpenFiltersIcon } from "../../assets/icons/ListSale/open-filters.svg";
import Map from "../PropertyMap/Map/Map";
import { getFavorite, getFavoriteGoods } from "../../data/slices/favoriteSlice";
import { handleStateChange } from "../../data/slices/goodsFilterSlice";
import RemoveFromFavorite from "./components/RemoveFromFavorite";
import { useNavigate } from "react-router-dom";
import SelectInput from "../GoodsList/components/SelectInput/SelectInput";
import { changeTab } from "../../data/slices/tabsSlice";

const GoodsFavorite = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.goodsFilters);

  const navigate = useNavigate();
  const [showCardsSection, setShowCardsSection] = useState(true);
  const [isFilterChange, setIsFilterChange] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { direction } = useSelector((state) => state.settings);
  const { favGoods, loading } = useSelector((state) => state.favorite);

  const items2 = [
    t("management_rent_org.sale"),
    t("management_rent_org.rent"),
    t("management_rent_org.daily_rent"),
    t("management_rent_org.action"),
  ];

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleChange = (e) => {
    handleBtnClick(
      "offer_type",
      e.target.value === 0
        ? "sell"
        : e.target.value === 1
        ? "rent"
        : e.target.value === 2
        ? "daily_rent"
        : "auction"
    );
  };

  const getFiltersObject = (obj) => {
    const filteredObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
    });

    if (!filteredObj.hasOwnProperty("country_code"))
      filteredObj["country_code"] = isAuthenticated ? "" : "IN";

    

    return filteredObj;
  };

  useEffect(() => {
    if (isFilterChange) {
      const dispatchFilters = getFiltersObject(filters);
      delete dispatchFilters?.type;

      dispatch(
        getFavoriteGoods({
          lang: direction === "ltr" ? "en" : "ar",
          body: dispatchFilters,
        })
      );
    }
  }, [...Object.values(filters), i18n?.language]);

  useEffect(() => {
    const dispatchFilters = getFiltersObject(filters);
    delete dispatchFilters?.type;

    dispatch(
      getFavoriteGoods({
        lang: direction === "ltr" ? "en" : "ar",
        body: dispatchFilters,
      })
    )
      .unwrap()
      .then(() => {
        setIsFilterChange(true);
      })
      .catch(() => {
        message.info("Please login first to access this page");
        navigate("/auth/login");
      });
  }, [i18n?.language]);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const handleChangePrefix = (e) => {
    const newTab = e.target.value;
    dispatch(changeTab(newTab));
    handleBtnClick(newTab);

    handleBtnClick(
      "auction_category",
      e.target.value == "future"
        ? "future"
        : e.target.value == "ended"
        ? "ended"
        : e.target.value == "current"
        ? "current"
        : "current"
    );
  };

  const items3 = [
    { title: t("auction_elements.future"), key: "future" },
    { title:t("auction_elements.current"), key: "current" },
    { title:t("auction_elements.ended"), key: "ended" },
  ];

  console.log(filters)

  return (
    <div className="list-sale">
      <RemoveFromFavorite />
      <h2 className="fav-title">{t("favorite.Favorite_Items")}</h2>
      <div className="tabs">
        <Radio.Group
          value={
            filters?.offer_type === "sell"
              ? 0
              : filters?.offer_type === "rent"
              ? 1
              : filters?.offer_type === "daily_rent"
              ? 2
              : 3
          }
          onChange={handleChange}
        >
          {items2?.map((item, index) => (
            <Radio.Button className="tabs-btn" value={index}>
              {item}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>

      {filters?.offer_type === "auction" && (
        <div className="tabs">
          <div className="tabs">
            <Radio.Group
              value={filters?.auction_category}
              onChange={handleChangePrefix}
            >
              {items3.map((item, index) => (
                <Radio.Button  className="tabs-btn small-tabs" value={item.key} key={index}>
                  {item.title}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
      )}

      <div className="content-container">
        <div
          className={`content ${
            !showCardsSection && "smaller-content-container"
          }`}
        >
          <div className="filters-content-container">
            <Filters />
          </div>

          {showCardsSection ? (
            <div className="right-content-container">
              <div className="input-content-container">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder={t("brokers.search_in_list")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button type="submit" className="register-btn">
                    <SearchIcon />
                  </button>
                </div>
              </div>

              <div className="menu-content">
                <div className="menu-btns">
                  <div
                    className={`menu-toggle-icon ${
                      showCardsSection && "menu-toggle-icon-active"
                    }`}
                    onClick={() => setShowCardsSection(false)}
                  >
                    <Frame1Icon />
                  </div>
                  <div
                    className={`menu-toggle-icon ${
                      !showCardsSection && "menu-toggle-icon-active"
                    }`}
                    onClick={() => setShowCardsSection(true)}
                  >
                    <Frame2Icon />
                  </div>
                </div>

                <div className="properties-filters-btns-container">
                  <div className="input-content-container2">
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder={t("brokers.search_in_list")}
                      />
                      <button type="submit" className="register-btn">
                        <SearchIcon />
                      </button>
                    </div>
                  </div>
                  <button className="btn-open" onClick={showDrawer}>
                    {t("properties.Filter")}
                    <OpenFiltersIcon />
                  </button>
                  <SelectInput />
                </div>
              </div>

              <div className="cards-container">
                {loading === "idle" ? (
                  favGoods?.filter(
                    (item) => item.offer_type === filters?.offer_type
                  )?.length > 0 &&
                  favGoods?.filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(search.toLowerCase().trim())
                  )?.length > 0 ? (
                    favGoods
                      ?.filter(
                        (item) => item.offer_type === filters?.offer_type
                      )
                      ?.filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(search.toLowerCase().trim())
                      )
                      ?.map((item, index) => (
                        <>
                          <Card
                            key={`card2-${index}`}
                            propertieItem={item}
                            favorite={true}
                          />
                        </>
                      ))
                  ) : (
                    <p style={{ textAlign: "center", marginTop: "22px" }}>
                      {t("brokers.no_data_found")}
                    </p>
                  )
                ) : (
                  <Spin />
                )}
              </div>
            </div>
          ) : (
            <div className="right-content-container">
              <div className="map-show-container">
                <div style={{ width: "100%", display: "grid", gap: "12px" }}>
                  <div className="input-content-container">
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder={t("brokers.search_in_list")}
                      />
                      <button type="submit" className="register-btn">
                        <SearchIcon />
                      </button>
                    </div>
                  </div>
                  <div className="menu-content">
                    <div className="menu-btns">
                      <div
                        className={`menu-toggle-icon ${
                          showCardsSection && "menu-toggle-icon-active"
                        }`}
                        onClick={() => setShowCardsSection(false)}
                      >
                        <Frame1Icon />
                      </div>
                      <div
                        className={`menu-toggle-icon ${
                          !showCardsSection && "menu-toggle-icon-active"
                        }`}
                        onClick={() => setShowCardsSection(true)}
                      >
                        <Frame2Icon />
                      </div>
                    </div>

                    <div className="properties-filters-btns-container">
                      <div className="input-content-container2">
                        <div className="input-container">
                          <input
                            type="text"
                            placeholder={t("brokers.search_in_list")}
                          />
                          <button type="submit" className="register-btn">
                            <SearchIcon />
                          </button>
                        </div>
                      </div>
                      <button
                        className="btn-open"
                        onClick={() => dispatch(openFilter())}
                      >
                        {t("properties.Filter")}
                        <OpenFiltersIcon />
                      </button>
                      <SelectInput />
                    </div>
                  </div>

                  <Map properties={favGoods} />
                </div>

                <div className="cards-container">
                  {loading === "idle" ? (
                    favGoods?.map((item, index) => (
                      <Card
                        key={`card-${index}`}
                        propertieItem={item}
                        favorite={true}
                        type="small"
                        anotherType={filters.offer_type}
                      />
                    ))
                  ) : (
                    <Spin />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Drawer
        placement={`${direction === "ltr" ? "left" : "right"}`}
        onClose={onClose}
        open={open}
      >
        <Filters />
      </Drawer>
    </div>
  );
};

export default GoodsFavorite;
