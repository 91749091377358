import "./_Tabs.scss";
import { useTranslation } from "react-i18next";
import { Modal, Radio, Select } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../data/slices/tabsSlice";

function Tabs({type}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let curr = "filters";

  curr = type === "goods" ? "goodsFilters" : "filters";

  const { currentTab } = useSelector((state) => state.tabs);
  const filters = useSelector((state) => state[curr]);

  const items2 = [
    t("management_rent_org.sale"),
    t("management_rent_org.rent"),
    t("management_rent_org.daily_rent"),
    t("management_rent_org.action"),
  ];

  
  return (
    <div className="tabs">
      <Radio.Group
        value={
          filters?.offer_type === "sell"
            ? 0
            : filters?.offer_type === "rent"
            ? 1
            : filters?.offer_type === "daily_rent"
            ? 2
            : 3
        }
      >
        {items2?.map((item, index) => (
          <Radio.Button className="tabs-btn" value={index}>
            {item}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
}

export default Tabs;
