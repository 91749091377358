import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import publicAxios from "../../utils/publicAxios";
import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  error: null,
  message: null,
  loading: "idle",
  updateUserLoading: "idle"
};

export const setSession = (token) => {
  if (token) {
    localStorage.setItem("aqari_token", token);
  } else {
    localStorage.removeItem("aqari_token");
    localStorage.removeItem("user_type");
  }
};

export const login = createAsyncThunk(
  "auth/login",
  async (values, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.post(`/login?lang=${lang}`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        if (data.error === 0) {
          setSession(data.token);
          localStorage.setItem("user_type", data?.user_info?.usertype);

          thunkApi.dispatch(getMe());
        }
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const register = createAsyncThunk("signup_mobile", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/register?lang=${lang}`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});
export const verifyemail = createAsyncThunk(
  "user/verifyemail",
  async (payload) => {
    const { code, email } = payload;
    let data;
    try {
      const response = await axiosInstance.post(`/verify_email`, payload);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
//forgot Pwd
export const forgotPwd = createAsyncThunk("api/forgot_pwd", async (values) => {
  let data;
  try {
    const response = await publicAxios.post(
      `/forgotpassword?lang=${lang}`,
      values
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//reset Pwd
export const resetPassword = createAsyncThunk(
  "api/reset_pwd",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(`/resetpassword`, values);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const forgotVerification = createAsyncThunk(
  "api/forgotVerification",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(`/forgotverification`, values);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
//get user info
export const getMe = createAsyncThunk("get/me", async () => {
  let data;
  try {
    const response = await axiosInstance.get(`/user/getuserinfo`);
    data = await response.data;
    initialise({ isAuthenticated: true, user: data });

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//update password
export const updateProfileUser = createAsyncThunk(
  "api/update-usr-profile",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `/user/update_user_profile?lang=${lang}`,
        values
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//update password
export const updateProfileOrg = createAsyncThunk(
  "api/update_user_profile",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(`/broker/update_user_profile`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
    },
    restore: (state) => {
      state.error = null;
      state.message = null;
    },
    logout: (state) => {
      setSession(null);
      state.isAuthenticated = false;
      state.user = null;
      window.location.href = "/";
      localStorage.removeItem("user_type");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [login.fulfilled]: (state, action) => {
      const { token, user_info } = action.payload;
    
      setSession(token);
      if (token) {
        state.isAuthenticated = true;
        state.user = user_info;
        window.location.href = "/";
      }
      state.loading = "idle";
    },
    [login.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //register
    [register.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [register.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [register.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //forgot pwd
    [forgotPwd.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [forgotPwd.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [forgotPwd.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //reset pwd
    [resetPassword.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //update profile
    [updateProfileUser.pending]: (state) => {
      state.error = null;
      state.updateUserLoading = "loading";
    },
    [updateProfileUser.fulfilled]: (state, action) => {
      state.updateUserLoading = "idle";
    },
    [updateProfileUser.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //update profile org
    [updateProfileOrg.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [updateProfileOrg.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [updateProfileOrg.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { initialise, logout, restore } = authSlice.actions;

export default authSlice.reducer;
