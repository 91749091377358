import CustomSwiper from "../../components/Swiper/CustomSwiper";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Tabs from "../../components/Tabs/Tabs";
import posIcon from "./assets/icons/pos.svg";
import "./PropertyDetails.css";

import Specifications from "./components/Specifications/specifications";
import RoomsAndOthers from "./components/RoomsAndOthers/RoomsAndOthers";
import Services from "./components/Services/Services";
import Facilities from "./components/Facilities/Facilities";
import Fourniture from "./components/Fourniture/Fourniture";
import fb from "./assets/icons/fb.svg";
import wts from "./assets/icons/whts.svg";
import tw from "./assets/icons/tw.svg";
import copy from "./assets/icons/copy.svg";
import arr from "./assets/icons/arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "./components/EmptyState/EmptyState";
import { getPropertyByID } from "../../data/slices/goodsListSlice";
import { Spin, Image, Radio } from "antd";
import Map from "../PropertyMap/Map/Map";
import useWindowSize from "../../hooks/useWindowSize";
import MobileTab from "./components/MobileTab/MobileTab";
import PriceSection from "./components/PriceSection/PriceSection";
import { openLinkInNewWindow } from "../../helpers/openMediaLink";
import handleName from "../../helpers/handleName";
import cookies from "js-cookie";
import CallSection from "./components/CallSection/CallSection";
import { getpropertytypes } from "../../data/slices/goodsListSlice";
import Tag from "./components/Tag/Tag";
import no_smoking_Icon from "../ListSale/assets/icons/no_smoking.svg";

function GoodsDetails() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { offer_type } = useLocation().state;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const data = useSelector((state) => state.goodsList);
  const { id } = useParams();
  const [property, setProperty] = useState();
  const [previewImg, setPreviewImg] = useState();
  const { property_types } = useSelector((state) => state.goodsList);
  const filters = useSelector((state) => state.goodsFilters);

  useEffect(() => {
    dispatch(
      getPropertyByID({
        body: {
          country_id: null,
          country_code: filters?.country_code,
          id: id,
          offer_type: offer_type,
        },
        lang: i18n?.language,
      })
    );
  }, [i18n?.language]);
  useEffect(() => {
    setProperty(data?.property);
  }, [data]);

  useEffect(() => {
    dispatch(
      getpropertytypes({
        country_code: filters.country_code,
        country_id: filters.country_id,
        lang: currentLanguageCode,
      })
    );
  }, [filters.country_id, i18n?.language]);

  if (data.loading === "loading") {
    return (
      <div className="spin_page">
        <Spin />
      </div>
    );
  }
  if (!property) {
    return (
      <div className="spin_page">
        <EmptyState text={t("property_details.no_data")} />
      </div>
    );
  }
  const handleNewUrl = (url) => {
    window.open(url, "_blank");
  };

  const items3 = [
    { title: t("auction_elements.future"), key: "future" },
    { title: t("auction_elements.current"), key: "current" },
    { title: t("auction_elements.ended"), key: "ended" },
  ];

  const openPDFInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="property_details">
      <div className="list-sale-filter">
        <div className="list-sale-filter-container full_tab">
          {property_types?.type_list && (
            <CustomSwiper items={property_types?.type_list} size="md" />
          )}
        </div>
      </div>
      <div className="tabs_details">
        <Tabs type={"goods"} />
      </div>

      {!property ? (
        <></>
      ) : (
        <div className="content">
          <div className="left">
            <p className="title">{property?.name}</p>
            <span className="position">
              <img src={posIcon} alt="" />
              {handleName(
                property?.address_ar,
                property?.address_en,
                currentLanguageCode
              )}
            </span>
            <p className="description">{property?.details}</p>

            {property?.offer_type === "daily_rent" &&
              property?.rent_specification?.smoking_prohibited === "yes" && (
                <div className="smoking_tag">
                  <Tag
                    title={
                      property?.rent_specification?.smoking_prohibited === "yes"
                        ? t("no_smoking")
                        : null
                    }
                    background="1b1c57"
                    icon={no_smoking_Icon}
                  />
                </div>
              )}

            {width > 900 && <CallSection property={property} />}
            {/* {width > 900 ? (
              <div className="items">
                <Specifications items={property?.specification_array} />
                <RoomsAndOthers items={property?.rooms_array || {}} />
                <Services items={property?.services_array} />
                <Facilities items={property?.specification_facilities_array} />
                <Fourniture items={property?.furniture_array} />
              </div>
            ) : (
              <MobileTab property={property} />
            )} */}
            {width < 900 && <CallSection property={property} />}

            <div className="pdf-wrapper">
              {property?.pdf_specification &&
                property.pdf_specification?.map((items) => {
                  return (
                    <Tag
                      onClick={() => {
                        openPDFInNewTab(items.url);
                      }}
                      title={items.title}
                      background="1b1c57"
                    />
                  );
                })}
            </div>

            <div className="post_date">
              {t("property_details.Post_Date")} :{" "}
              {property?.main_info_array?.listed_date}
            </div>
            <div className="share">
              <p className="title">{t("property_details.share")} :</p>
              <p className="value">
                <a
                  href={`https://api.whatsapp.com/send?phone=${property?.whatsapp}`}
                  target="_blank"
                >
                  <img src={wts} alt="" />
                </a>
                <a
                  href={`/${property?.user_details?.selling_agent?.facebook}`}
                  target="_blank"
                >
                  <img src={fb} alt="" />
                </a>
                <a
                  href={property?.user_details?.selling_agent?.twitter}
                  target="_blank"
                >
                  <img src={tw} alt="" />
                </a>
              </p>
            </div>
            <div className="ref_num">
              <p className="title_ref">
                {t("property_details.Reference_number")} :
              </p>
              <p className="value_ref">
                {property?.ref_id} <img src={copy} alt="" />
              </p>
            </div>

            <div className="map-mobile">
              {/* <Map property={property} />
               */}
              <Map properties={[property]} />
            </div>

            <div className="back" onClick={() => navigate(-1)}>
              <img src={arr} alt="" /> <span>{t("property_details.Back")}</span>
            </div>
          </div>

          <div className="right">
            <Image
              src={previewImg || property?.main_image}
              className="principal_img"
              width="100%"
              preview={false}
              onClick={() => openLinkInNewWindow(property?.media_url)}
            />

            <PriceSection property={property} type={property?.offer_type} />

            <div className="map">
              {/* <Map property={property} /> */}
              <Map properties={[property]} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GoodsDetails;
