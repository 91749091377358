import React, { useEffect, useState } from "react";
import Pricing from "../Pricing/Pricing";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/icons/Filters/arrow up.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleStateChange } from "../../../../data/slices/goodsFilterSlice";
import DailyRentPrices from "../DailyRentPricesFilters/DailyRentPrices";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import Slider from "../Inputs/Slider/Slider";
import { InputNumber } from "antd";
import "./AuctionFilter.css";

function AuctionFilter() {
  const [open, setOpen] = useState(true);
  const [day, setDay] = useState(3);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { specifications, default_values, currency_icon } = useSelector(
    (state) => state.goodsList
  );

  const properties = useSelector((state) => state.goodsList);

  const {
    minprice,
    maxprice,
    price_per_meter_monthly_range,
    price_per_meter_yearly_range,
    price_per_meter,
    toll_price_per_meter_range,
    sell_available,
    toll_price,
    toll_available,
    payment_method,
    last_toll_price_range,
    last_toll_price_per_meter_range,
    days_since,
    auction_type,
    auction_category,
    bidding_start_price_range,
    sell_price_range,
    bidding_start_price_per_meter_range,
    sell_price_per_meter_range,
  } = useSelector((state) => state.goodsFilters);

  const filters = useSelector((state) => state.goodsFilters);

  const handleBtnClick = (target, event) => {
    const { name, checked } = event.target;

    const result = () => {
      // If both checkboxes are checked
      if (name === "online" && checked && auction_type === "offline") {
        return "online,offline";
      } else if (name === "offline" && checked && auction_type === "online") {
        return "online,offline";
      }
      // If only one checkbox is checked, set state accordingly
      else if (checked && !auction_type) {
        return name;
      }
      // If no checkbox is checked, set state to null
      else if (!checked && auction_type) {
        // If the unchecked checkbox is the one that is present in the state, set the state to null
        if (auction_type === name) {
          return null;
        }

        if (auction_type.split(",")[0] === name) {
          return auction_type.split(",")[1];
        } else {
          return auction_type.split(",")[0];
        }
      }
    };

    dispatch(
      handleStateChange({
        target,
        value: result(),
      })
    );
  };

  const handleClick = (inc) => {
    if (inc) {
      dispatch(
        handleStateChange({
          target: "days_since",
          value: days_since + 1,
        })
      );
    } else {
      dispatch(
        handleStateChange({
          target: "days_since",
          value: days_since - 1,
        })
      );
    }
  };

  console.log(
    auction_category === "current"
      ? default_values?.last_toll_price?.[1] ?? 0
      : auction_category === "future"
      ? default_values?.bidding_start_price?.[1] ?? 0
      : default_values?.auction_sell_price?.[1] ?? 0
  );

  useEffect(() => {
    if (
      auction_category === "current"
        ? default_values?.last_toll_price?.[1] ?? 0
        : auction_category === "future"
        ? default_values?.bidding_start_price?.[1] ?? 0
        : default_values?.auction_sell_price?.[1] ?? 0 == 0
    ) {
      dispatch(
        handleStateChange({
          target:
            auction_category === "current"
              ? "last_toll_price_range"
              : auction_category === "future"
              ? "bidding_start_price_range"
              : "sell_price_range",
          value: null,
        })
      );
    }

    if (
      auction_category === "current"
        ? default_values?.last_toll_price_per_meter?.[1] ?? 0
        : auction_category === "future"
        ? default_values?.bidding_start_price_per_meter?.[1] ?? 0
        : default_values?.sell_price_per_meter?.[1] ?? 0 == 0
    ) {
      dispatch(
        handleStateChange({
          target:
            auction_category === "current"
              ? "last_toll_price_per_meter_range"
              : auction_category === "future"
              ? "bidding_start_price_per_meter_range"
              : "sell_price_per_meter_range",
          value: null,
        })
      );
    }
  }, [default_values]);

  console.log(
    auction_category === "current"
      ? last_toll_price_range?.split("-")[1] ?? 0
      : auction_category === "future"
      ? bidding_start_price_range?.split("-")[1] ?? 0 ?? 0
      : sell_price_range?.split("-")[1] ?? 0
  );

  return (
    <>
      <button className="collapse-btn" onClick={() => setOpen(!open)}>
        {t("filters.Pricing_filters")}
        <ArrowUpIcon className={!open ? "flip-arrow" : ""} />
      </button>
      {open ? (
        <div className="filters-content-container">
          <div className="pricing-filters">
            <>
              <div className="price-determined-container">
                <Checkbox
                  label={t("auction_elements.offline")}
                  checked={
                    auction_type === "offline" ||
                    auction_type === "online,offline"
                  }
                  onClick={(e) => handleBtnClick("auction_type", e)}
                  name="offline"
                />
                <Checkbox
                  label={t("auction_elements.online")}
                  checked={
                    auction_type === "online" ||
                    auction_type === "online,offline"
                  }
                  onClick={(e) => handleBtnClick("auction_type", e)}
                  name="online"
                />
              </div>

              <p className="label">
                {auction_category === "current"
                  ? `${t("auction_elements.last_toll")}(${
                      properties.currency_key
                    }):`
                  : auction_category === "future"
                  ? `${t("auction_elements.bidding")}(${
                      properties.currency_key
                    }):`
                  : `${t("auction_elements.sell")}(${
                      properties.currency_key
                    }):`}
              </p>
              <Slider
                min={
                  auction_category === "current"
                    ? default_values?.last_toll_price?.[0] ?? 0
                    : auction_category === "future"
                    ? default_values?.bidding_start_price?.[0] ?? 0
                    : default_values?.auction_sell_price?.[0] ?? 0
                }
                max={
                  auction_category === "current"
                    ? default_values?.last_toll_price?.[1] ?? 0
                    : auction_category === "future"
                    ? default_values?.bidding_start_price?.[1] ?? 0
                    : default_values?.auction_sell_price?.[1] ?? 0
                }
                values={[
                  auction_category === "current"
                    ? last_toll_price_range?.split("-")[0] ?? 0
                    : auction_category === "future"
                    ? bidding_start_price_range?.split("-")[0] ?? 0
                    : sell_price_range?.split("-")[0] ?? 0,
                  auction_category === "current"
                    ? last_toll_price_range?.split("-")[1] ?? 0
                    : auction_category === "future"
                    ? bidding_start_price_range?.split("-")[1] ?? 0 ?? 0
                    : sell_price_range?.split("-")[1] ?? 0,
                ]}
                target={
                  auction_category === "current"
                    ? "last_toll_price_range"
                    : auction_category === "future"
                    ? "bidding_start_price_range"
                    : "sell_price_range"
                }
                unit={currency_icon}
              />

              <p className="label">
                {auction_category === "current"
                  ? `${t("auction_elements.last_toll_meter")}(${
                      properties.currency_key
                    }):`
                  : auction_category === "future"
                  ? `${t("auction_elements.bidding_meter")}(${
                      properties.currency_key
                    }):`
                  : `${t("auction_elements.sell_per_meter")}(${
                      properties.currency_key
                    }):`}
              </p>
              <Slider
                min={
                  auction_category === "current"
                    ? default_values?.last_toll_price_per_meter?.[0] ?? 0
                    : auction_category === "future"
                    ? default_values?.bidding_start_price_per_meter?.[0] ?? 0
                    : default_values?.sell_price_per_meter?.[0] ?? 0
                }
                max={
                  auction_category === "current"
                    ? default_values?.last_toll_price_per_meter?.[1] ?? 0
                    : auction_category === "future"
                    ? default_values?.bidding_start_price_per_meter?.[1] ?? 0
                    : default_values?.sell_price_per_meter?.[1] ?? 0
                }
                values={[
                  auction_category === "current"
                    ? last_toll_price_per_meter_range?.split("-")[0] ?? 0
                    : auction_category === "future"
                    ? bidding_start_price_per_meter_range?.split("-")[0] ?? 0
                    : sell_price_per_meter_range?.split("-")[0],
                  auction_category === "current"
                    ? last_toll_price_per_meter_range?.split("-")[1] ?? 0
                    : auction_category === "future"
                    ? bidding_start_price_per_meter_range?.split("-")[1] ?? 0
                    : sell_price_per_meter_range?.split("-")[1] ?? 0,
                ]}
                target={
                  auction_category === "current"
                    ? "last_toll_price_per_meter_range"
                    : auction_category === "future"
                    ? "bidding_start_price_per_meter_range"
                    : "sell_price_per_meter_range"
                }
                unit={currency_icon}
              />
              <div className="input-wrap">
                <p className="label">
                  {t("auction_elements.days_left") + " :"}
                </p>
                <div className="day-since-wrap">
                  {days_since ? days_since : 0}

                  <div className="arrow-wrapper">
                    <svg
                      style={{ marginBottom: "-4px" }}
                      onClick={() => handleClick(true)}
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5424 10.3637L8.87492 7.69619L6.20742 10.3637C6.14377 10.4273 6.06821 10.4778 5.98504 10.5123C5.90188 10.5467 5.81275 10.5645 5.72273 10.5645C5.63272 10.5645 5.54359 10.5467 5.46042 10.5123C5.37726 10.4778 5.3017 10.4273 5.23805 10.3637C5.1744 10.3 5.12391 10.2245 5.08946 10.1413C5.05501 10.0581 5.03728 9.96902 5.03728 9.879C5.03728 9.78899 5.05501 9.69985 5.08946 9.61669C5.12391 9.53353 5.1744 9.45796 5.23805 9.39431L8.39367 6.23869C8.45727 6.17495 8.53282 6.12439 8.61599 6.08989C8.69916 6.05539 8.78832 6.03763 8.87836 6.03763C8.9684 6.03763 9.05756 6.05539 9.14073 6.08989C9.22389 6.12439 9.29944 6.17495 9.36305 6.23869L12.5187 9.39431C12.5824 9.45792 12.633 9.53347 12.6675 9.61663C12.702 9.6998 12.7197 9.78896 12.7197 9.879C12.7197 9.96904 12.702 10.0582 12.6675 10.1414C12.633 10.2245 12.5824 10.3001 12.5187 10.3637C12.2505 10.6249 11.8105 10.6318 11.5424 10.3637Z"
                        fill="black"
                      />
                    </svg>

                    <svg
                      style={{ marginTop: "-4px" }}
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleClick(false)}
                      className="flip-arrow"
                    >
                      <path
                        d="M11.5424 10.3637L8.87492 7.69619L6.20742 10.3637C6.14377 10.4273 6.06821 10.4778 5.98504 10.5123C5.90188 10.5467 5.81275 10.5645 5.72273 10.5645C5.63272 10.5645 5.54359 10.5467 5.46042 10.5123C5.37726 10.4778 5.3017 10.4273 5.23805 10.3637C5.1744 10.3 5.12391 10.2245 5.08946 10.1413C5.05501 10.0581 5.03728 9.96902 5.03728 9.879C5.03728 9.78899 5.05501 9.69985 5.08946 9.61669C5.12391 9.53353 5.1744 9.45796 5.23805 9.39431L8.39367 6.23869C8.45727 6.17495 8.53282 6.12439 8.61599 6.08989C8.69916 6.05539 8.78832 6.03763 8.87836 6.03763C8.9684 6.03763 9.05756 6.05539 9.14073 6.08989C9.22389 6.12439 9.29944 6.17495 9.36305 6.23869L12.5187 9.39431C12.5824 9.45792 12.633 9.53347 12.6675 9.61663C12.702 9.6998 12.7197 9.78896 12.7197 9.879C12.7197 9.96904 12.702 10.0582 12.6675 10.1414C12.633 10.2245 12.5824 10.3001 12.5187 10.3637C12.2505 10.6249 11.8105 10.6318 11.5424 10.3637Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AuctionFilter;
