import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { setDefaultValues, setSpecifications } from "./propertiesSlice";
import { setDefaultValues as setVal, setSpecifications as setSpec } from "./goodsListSlice";
import cookies from "js-cookie";
const currentLanguageCode = cookies.get("i18next") || "en";

const initialState = {
  favorite: [],
  favGoods : [],
  removeFromFavorite: null,
  error: null,
  message: null,
  loading: "idle",
};

export const getFavorite = createAsyncThunk(
  "api/favorite",
  async ({ lang, body }, thunkApi) => {
    let data;
    try {
      // const response = await axiosInstance.post(`/user/myfavorite?lang=${lang}`, { ...body });
      const response = await axiosInstance.post(
        `/user/myfavorite?lang=${lang}`,
        {
          ...body,
        }
      );
      data = await response.data;
      if (response.status == 200) {
        thunkApi.dispatch(setSpecifications(data.specifications));
        thunkApi.dispatch(setDefaultValues(data.filter));

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getFavoriteGoods = createAsyncThunk(
  "api/goodsuser/myfavorite",
  async ({ lang, body }, thunkApi) => {
    let data;
    try {
      // const response = await axiosInstance.post(`/user/myfavorite?lang=${lang}`, { ...body });
      const response = await axiosInstance.post(
        `/goodsuser/myfavorite?lang=${lang}`,
        {
          ...body,
        }
      );
      data = await response.data;
      if (response.status == 200) {
        thunkApi.dispatch(setSpec(data.specifications));
        thunkApi.dispatch(setVal(data.filter));

        return data;
      }
      
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err)
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const AddRemoveFavorite = createAsyncThunk(
  "api/AddRemoveFavorite",
  async ({ body }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.post(`/user/addremovefavorite`, {
        ...body,
      });
      data = await response.data;
      if (response.status == 200) {
        thunkApi.dispatch(getFavorite({ lang: currentLanguageCode, body: {} }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const AddRemoveFavoriteGoods = createAsyncThunk(
  "api/goodsuser/addremovefavorite",
  async ({ body }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.post(`/goodsuser/addremovefavorite`, {
        ...body,
      });
      data = await response.data;
      if (response.status == 200) {
        thunkApi.dispatch(getFavoriteGoods({ lang: currentLanguageCode, body: {} }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    updateRemoveFromFavorite: (state, action) => {
      state.removeFromFavorite = action.payload;
    },
   
  },
  extraReducers: {
    [getFavorite.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getFavorite.fulfilled]: (state, action) => {
      const { properties, filter } = action.payload;
      state.favorite = properties;
      state.loading = "idle";
    },
    [getFavorite.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getFavoriteGoods.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getFavoriteGoods.fulfilled]: (state, action) => {
      const { goods, filter } = action.payload;
      state.favGoods = goods;
      state.loading = "idle";
    },
    [getFavoriteGoods.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { updateRemoveFromFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
