import { useRef } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as DefaultLeftIcon } from '../../assets/icons/landing/left.svg';
import { ReactComponent as DefaultRightIcon } from '../../assets/icons/landing/right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { handleStateChange } from '../../data/slices/filtersSlice';

const CustomSwiper = ({
  items,
  LeftIcon = DefaultLeftIcon,
  RightIcon = DefaultRightIcon,
  size = 'sm', // sm or md
  type = 'text', // text or image
}) => {
  const rtl = document.body.dir === 'rtl';

  const filters = useSelector((state) => state.filters);

  const dispatch = useDispatch();
  const swiperRef = useRef();

  const slidesPerView = size === 'sm' ? 5 : 10;
  const slidesItems = size === 'sm' ? 1 : 2;

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleChange = (e) => {
    handleBtnClick('type', e);
  };
  return (
    <>
      <div className='btn' onClick={() => swiperRef.current?.slidePrev()}>
        {rtl ? <RightIcon /> : <LeftIcon />}
      </div>
      <Swiper
        visibilityFullFit={true}
        autoResize={true}
        slidesPerView={'auto'}
        rtl={rtl}
        // slidesPerView={2}
        spaceBetween={12}
        // breakpoints={{
        //   1920: {
        //     slidesPerView: items?.length > slidesPerView ? slidesPerView : items?.length,
        //   },
        //   1440: {
        //     slidesPerView:
        //       items?.length > slidesPerView - slidesItems
        //         ? slidesPerView - slidesItems
        //         : items?.length,
        //   },
        //   1024: {
        //     slidesPerView:
        //       items?.length > slidesPerView - 2 * slidesItems
        //         ? slidesPerView - 2 * slidesItems
        //         : items?.length,
        //   },
        //   768: {
        //     slidesPerView:
        //       items?.length > slidesPerView - 3 * slidesItems
        //         ? slidesPerView - 3 * slidesItems
        //         : items?.length,
        //   },
        //   425: {
        //     slidesPerView:
        //       items?.length > slidesPerView - 4 * slidesItems
        //         ? slidesPerView - 4 * slidesItems
        //         : items?.length,
        //   },
        // }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {items?.map((el, index) => (
          <SwiperSlide key={index}>
            {type === 'text' ? (
              <div
                className={`items-btn ${filters?.type === el?.type_id ? 'items-btn-active' : ''}`}
                onClick={() => handleChange(el?.type_id)}
              >
                {el?.type_name}
              </div>
            ) : (
              <img src={el} alt='img' />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='btn' onClick={() => swiperRef.current?.slideNext()}>
        {rtl ? <LeftIcon /> : <RightIcon />}
      </div>
    </>
  );
};

export default CustomSwiper;
