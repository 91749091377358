import Location from "./components/Location/Location";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/Filters/arrow up.svg";
import { useState } from "react";
import Pricing from "./components/Pricing/Pricing";
import Specifications from "./components/Specifications/Specifications";
import Rooms from "./components/Rooms/Rooms";
import Services from "./components/Services/Services";
import Facilities from "./components/Facilities/Facilities";
import Fourniture from "./components/Fourniture/Fourniture";
import { useTranslation } from "react-i18next";
// import useWindowSize from '../../hooks/useWindowSize';
// import closeIcon from '../../assets/icons/close1.svg';
// import { closeFilter } from '../../data/slices/settingsSlice';
import { useDispatch, useSelector } from "react-redux";
import AuctionFilter from "./components/AuctionFilter/AuctionFilter.jsx";
import AuctionSpecification from "./components/AuctionSpecification/AuctionSpecification.jsx";
const Filters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pricingCollasped, setPriceCollasped] = useState(false);
  const [specificationsCollasped, setSpecificationsCollasped] = useState(false);
  const [roomsCollasped, setRoomsCollasped] = useState(false);
  const [servicesCollasped, setServicesCollasped] = useState(false);
  const [facilitiesCollasped, setFacilitiesCollasped] = useState(false);
  const [fournitureCollasped, setFournitureCollasped] = useState(false);
  // const { width } = useWindowSize();
  const { specifications } = useSelector((state) => state.properties);
  const filters = useSelector((state) => state.filters);



  return (
    <div className="filter-container">
      {/* {width > 1255 ? (
        <h3 className='title'>{t('filters.Filter_Search')}</h3>
      ) : (
        <img
          className='close_icon'
          onClick={() => dispatch(closeFilter())}
          src={closeIcon}
          alt=''
        />
      )} */}

      {/* Location filters */}
      <Location />
      {filters.offer_type === "auction" ? (
        <>
          <AuctionFilter />
          <AuctionSpecification />
          <button
            className="collapse-btn"
            onClick={() => setRoomsCollasped(!roomsCollasped)}
          >
            {t("filters.Rooms_and_others_filters")}
            <ArrowUpIcon className={roomsCollasped ? "flip-arrow" : ""} />
          </button>

          {!roomsCollasped ? (
            <div className="filters-content-container">
              <Rooms />
            </div>
          ) : null}
          {/* Services filters */}
         
            <button
              className="collapse-btn"
              onClick={() => setServicesCollasped(!servicesCollasped)}
            >
              {t("filters.Services_filters")}
              <ArrowUpIcon className={servicesCollasped ? "flip-arrow" : ""} />
            </button>
        
          {!servicesCollasped ? (
            <div className="filters-content-container">
              <Services />
            </div>
          ) : null}
          {/* Services filters */}
         <button
              className="collapse-btn"
              onClick={() => setFacilitiesCollasped(!facilitiesCollasped)}
            >
              {t("filters.Facilities_filters")}
              <ArrowUpIcon
                className={facilitiesCollasped ? "flip-arrow" : ""}
              />
            </button>
          
          {!facilitiesCollasped ? (
            <div className="filters-content-container">
              <Facilities />
            </div>
          ) : null}
          {/* Services filters */}
          
            <button
              className="collapse-btn"
              onClick={() => setFournitureCollasped(!fournitureCollasped)}
            >
              {t("filters.Fourniture_filters")}
              <ArrowUpIcon
                className={fournitureCollasped ? "flip-arrow" : ""}
              />
            </button>
          
          {!fournitureCollasped ? (
            <div className="filters-content-container">
              <Fourniture />
            </div>
          ) : null}
        </>
      ) : (
        <>
          {" "}
          {/* Pricing filters */}
          <button
            className="collapse-btn"
            onClick={() => setPriceCollasped(!pricingCollasped)}
          >
            {t("filters.Pricing_filters")}
            <ArrowUpIcon className={pricingCollasped ? "flip-arrow" : ""} />
          </button>
          {!pricingCollasped ? (
            <div className="filters-content-container">
              <Pricing />
            </div>
          ) : null}
          {/* Specifications filters */}
        
            <button
              className="collapse-btn"
              onClick={() =>
                setSpecificationsCollasped(!specificationsCollasped)
              }
            >
              {t("filters.Specifications_filters")}
              <ArrowUpIcon
                className={specificationsCollasped ? "flip-arrow" : ""}
              />
            </button>
         
          {!specificationsCollasped ? (
            <div className="filters-content-container">
              <Specifications />
            </div>
          ) : null}
          {/* Specifications filters */}
         
            <button
              className="collapse-btn"
              onClick={() => setRoomsCollasped(!roomsCollasped)}
            >
              {t("filters.Rooms_and_others_filters")}
              <ArrowUpIcon className={roomsCollasped ? "flip-arrow" : ""} />
            </button>
          
          {!roomsCollasped ? (
            <div className="filters-content-container">
              <Rooms />
            </div>
          ) : null}
          {/* Services filters */}
       
            <button
              className="collapse-btn"
              onClick={() => setServicesCollasped(!servicesCollasped)}
            >
              {t("filters.Services_filters")}
              <ArrowUpIcon className={servicesCollasped ? "flip-arrow" : ""} />
            </button>
          
          {!servicesCollasped ? (
            <div className="filters-content-container">
              <Services />
            </div>
          ) : null}
          {/* Services filters */}
         
            <button
              className="collapse-btn"
              onClick={() => setFacilitiesCollasped(!facilitiesCollasped)}
            >
              {t("filters.Facilities_filters")}
              <ArrowUpIcon
                className={facilitiesCollasped ? "flip-arrow" : ""}
              />
            </button>
          
          {!facilitiesCollasped ? (
            <div className="filters-content-container">
              <Facilities />
            </div>
          ) : null}
          {/* Services filters */}
          
            <button
              className="collapse-btn"
              onClick={() => setFournitureCollasped(!fournitureCollasped)}
            >
              {t("filters.Fourniture_filters")}
              <ArrowUpIcon
                className={fournitureCollasped ? "flip-arrow" : ""}
              />
            </button>
          
          {!fournitureCollasped ? (
            <div className="filters-content-container">
              <Fourniture />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Filters;
