import { useTranslation } from "react-i18next";
import Slider from "../Inputs/Slider/Slider";
import YearSlider from "../Inputs/Slider/YearSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStateChange,
  handleStateChangeMultiple,
} from "../../../../data/slices/filtersSlice";

const Specifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    area_by_square_meter_range,
    area_by_length_range,
    area_by_width_range,
    area_by_height_range,
    include_mezzanine,
    entrance,
    unit_finishing,
    unit_direction,
    is_furnished,
    sea_view,
    floor,
    unit_usage,
    building_first_inhabited,
    building_age_in_years_range,
  } = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleBtnClickMultiple = (target, value) => {
    dispatch(
      handleStateChangeMultiple({
        target,
        value,
      })
    );
  };

  

  const { specifications, default_values } = useSelector(
    (state) => state.properties
  );

  return (
    <div className="specifications-filters">
      <>
        <p className="label">{t("filters.Usage")}</p>
        <div className="btns-list">
          <button
            className={`btn ${
              unit_usage?.includes("residential") && "btn-active"
            }`}
            onClick={() => handleBtnClickMultiple("unit_usage", "residential")}
          >
            {t("filters.Residential")}
          </button>
          <button
            className={`btn ${
              unit_usage?.includes("commercial") && "btn-active"
            }`}
            onClick={() => handleBtnClickMultiple("unit_usage", "commercial")}
          >
            {t("filters.Commercial")}
          </button>
          {unit_usage?.includes("residential") && (
            <>
              <span>|</span>
              <button
                className={`btn ${
                  unit_usage?.includes("singles") && "btn-active"
                }`}
                onClick={() => handleBtnClickMultiple("unit_usage", "singles")}
              >
                {t("filters.For_singles")}
              </button>
              <button
                className={`btn ${
                  unit_usage?.includes("families") && "btn-active"
                }`}
                onClick={() => handleBtnClickMultiple("unit_usage", "families")}
              >
                {t("filters.For_families")}
              </button>
            </>
          )}
        </div>
      </>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Area_by_square_meter")}</p>
        <Slider
          min={default_values?.area_by_square_meter?.[0] ?? 0}
          max={default_values?.area_by_square_meter?.[1] ?? 0}
          values={[
            area_by_square_meter_range?.split("-")[0],
            area_by_square_meter_range?.split("-")[1],
          ]}
          target="area_by_square_meter_range"
          unit=""
        />
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Length_by_meter")}</p>
        <Slider
          min={default_values?.area_by_length?.[0] ?? 0}
          max={default_values?.area_by_length?.[1] ?? 0}
          values={[
            area_by_length_range?.split("-")[0],
            area_by_length_range?.split("-")[1],
          ]}
          target="area_by_length_range"
          unit=""
        />
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Width_by_meter")}</p>
        <Slider
          min={default_values?.area_by_width?.[0] ?? 0}
          max={default_values?.area_by_width?.[1] ?? 0}
          values={[
            area_by_width_range?.split("-")[0],
            area_by_width_range?.split("-")[1],
          ]}
          target="area_by_width_range"
          unit=""
        />
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Height_by_meter")}</p>
        <Slider
          min={default_values?.area_by_height?.[0] ?? 0}
          max={default_values?.area_by_height?.[1] ?? 0}
          values={[
            area_by_height_range?.split("-")[0],
            area_by_height_range?.split("-")[1],
          ]}
          target="area_by_height_range"
          unit=""
        />
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Building_Age")}</p>
        <div className="btns-list">
          <button
            className={`btn ${
              building_first_inhabited?.includes("yes") && "btn-active"
            }`}
            onClick={() =>
              handleBtnClickMultiple("building_first_inhabited", "yes")
            }
          >
            {t("filters.first_inhabitant")}
          </button>
          <button
            className={`btn ${
              building_first_inhabited?.includes("no") && "btn-active"
            }`}
            onClick={() =>
              handleBtnClickMultiple("building_first_inhabited", "no")
            }
          >
            {t("filters.Previously_inhabited")}
          </button>
        </div>
      </div>

      <YearSlider
        min={default_values?.building_age?.[0] ?? 0}
        max={default_values?.building_age?.[1] ?? 0}
        values={[
          building_age_in_years_range?.split("-")[0],
          building_age_in_years_range?.split("-")[1],
        ]}
        target="building_age_in_years_range"
      />

      <div className="btns-list">
        <p className="label">{t("filters.Mezzanine")}</p>
        <button
          className={`btn ${
            include_mezzanine?.includes("yes") && "btn-active"
          }`}
          onClick={() => handleBtnClickMultiple("include_mezzanine", "yes")}
        >
          {t("filters.Include_Mezzanine")}
        </button>
        <button
          className={`btn ${include_mezzanine?.includes("no") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("include_mezzanine", "no")}
        >
          {t("filters.Without_Mezzanine")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Entrance_way")}</p>
        <button
          className={`btn ${entrance?.includes("private") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("entrance", "private")}
        >
          {t("filters.Private_Entrance")}
        </button>
        <button
          className={`btn ${entrance?.includes("shared") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("entrance", "shared")}
        >
          {t("filters.Shared_Entrance")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Unit_finishing")}</p>
        <button
          className={`btn ${unit_finishing?.includes("shell") && "btn-active"}`}
          onClick={() => handleBtnClick("unit_finishing", "shell")}
        >
          {t("filters.Shell")}
        </button>
        <button
          className={`btn ${unit_finishing === "finished" && "btn-active"}`}
          onClick={() => handleBtnClick("unit_finishing", "finished")}
        >
          {t("filters.Finished")}
        </button>
        <button
          className={`btn ${unit_finishing === "unfinished" && "btn-active"}`}
          onClick={() => handleBtnClick("unit_finishing", "unfinished")}
        >
          {t("filters.Unfinished")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Total_floors")}</p>
        <button
          className={`btn ${floor?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClick("floor", "1")}
        >
          {t("filters.One_Floor")}
        </button>
        <button
          className={`btn ${floor?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClick("floor", "more")}
        >
          {t("filters.More_Floors")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Unit_direction")}</p>
        <button
          className={`btn ${unit_direction?.includes("north") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("unit_direction", "north")}
        >
          {t("filters.North")}
        </button>
        <button
          className={`btn ${unit_direction?.includes("east") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("unit_direction", "east")}
        >
          {t("filters.East")}
        </button>
        <button
          className={`btn ${unit_direction?.includes("west") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("unit_direction", "west")}
        >
          {t("filters.South")}
        </button>
        <button
          className={`btn ${unit_direction?.includes("south") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("unit_direction", "south")}
        >
          {t("filters.West")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Fourniture")}</p>
        <button
          className={`btn ${is_furnished?.includes("yes") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("is_furnished", "yes")}
        >
          {t("filters.Fournished")}
        </button>
        <button
          className={`btn ${is_furnished?.includes("no") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("is_furnished", "no")}
        >
          {t("filters.Unfournished")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Sea_view_")}</p>
        <button
          className={`btn ${sea_view?.includes("yes") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("sea_view", "yes")}
        >
          {t("filters.Sea_view")}
        </button>
        <button
          className={`btn ${sea_view?.includes("no") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("sea_view", "no")}
        >
          {t("filters.not_Sea_View")}
        </button>
      </div>
    </div>
  );
};

export default Specifications;
