import React from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { ReactComponent as Info1Icon } from "../../../../../assets/images/Favorites/info1.svg";
import { useTranslation } from "react-i18next";
import "./_window.scss";
import ImageNotFound from "../../../../../assets/images/image-not-found.jpg";
import { openLinkInNewWindow } from "../../../../../helpers/openMediaLink";

function Window({ selectedPlace, category }) {
  const { t } = useTranslation();

  let propertieItem = selectedPlace;

  return (
    <InfoWindowF
      position={{
        lat: Number(selectedPlace?.lat_long?.split(",")[0]?.trim()),
        lng: Number(selectedPlace?.lat_long?.split(",")[1]?.trim()),
      }}
      zIndex={1}
      options={{
        pixelOffset: {
          width: 0,
          height: -40,
        },
      }}
    >
      <div>
        <div className="map-info-window-content">
          <img
            src={selectedPlace?.main_image || ImageNotFound}
            alt="no images"
            onClick={() => openLinkInNewWindow(selectedPlace?.media_url)}
          />
          {selectedPlace?.offer_type === "sell" && (
            <div className="items_map">
              <p className="title">{selectedPlace?.name}</p>
              <p className="subtitle">
                {selectedPlace?.price?.sell?.price} {t("price_content.RS")}{" "}
                <span>
                  {selectedPlace?.price?.sell?.price_per_meter}{" "}
                  {t("price_content.RS")}/Meter
                </span>
              </p>
              <p className="info area_map">
                <Info1Icon /> {selectedPlace?.specification_array?.unit_area}{" "}
                {t("properties.m")}
              </p>
            </div>
          )}
          {selectedPlace?.offer_type === "auction" && (
            <div className="items_map">
              <p className="title">{selectedPlace?.name}</p>
              <p className="subtitle">
                {propertieItem.auction?.ended_status
                  ? propertieItem?.price?.sell?.price
                  : propertieItem?.auction?.last_toll_price > 0
                  ? propertieItem?.auction?.last_toll_price
                  : propertieItem?.auction?.bidding_start_price}{" "}
                {propertieItem.currency_key} {propertieItem?.currency_key}{" "}
                <span>
                  {category === "current"
                    ? propertieItem?.auction?.last_toll_price_per_meter
                    : category === "future"
                    ? propertieItem?.auction?.bidding_start_price_per_meter
                    : category === "ended"
                    ? propertieItem?.auction?.sell_price_per_meter
                    : propertieItem?.price?.sell?.price_per_meter}{" "}
                  {propertieItem?.currency_key} {propertieItem?.currency_key}
                  /Meter
                </span>
              </p>
              <p className="info area_map">
                <Info1Icon /> {selectedPlace?.specification_array?.unit_area}{" "}
                {t("properties.m")}
              </p>
            </div>
          )}
          {selectedPlace?.offer_type === "rent" && (
            <div className="items_map">
              <p className="title">{selectedPlace?.name}</p>
              <p className="subtitle">
                {selectedPlace?.price?.monthly?.price} {t("price_content.RS")}{" "}
                {t("price_content.monthly")}
              </p>
              <p className="subtitle yearly">
                {selectedPlace?.price?.yearly?.price} {t("price_content.RS")}{" "}
                {t("price_content.yearly")}
              </p>
            </div>
          )}
          {selectedPlace?.offer_type === "daily_rent" && (
            <div className="items_map">
              <p className="title">{selectedPlace?.name}</p>

              <p className="subtitle yearly">
                {selectedPlace?.property_default_price} {t("price_content.RS")}
              </p>
            </div>
          )}
        </div>
      </div>
    </InfoWindowF>
  );
}

export default Window;
