import { ReactComponent as FavIcon } from "../../../../assets/icons/ListSale/Fav.svg";
import { ReactComponent as FavFillIcon } from "../../../../assets/icons/ListSale/FavFill.svg";
import { ReactComponent as LocIcon } from "../../../../assets/icons/ListSale/Loc.svg";
import { ReactComponent as Info1Icon } from "../../../../assets/images/Favorites/info1.svg";
import { ReactComponent as Info2Icon } from "../../../../assets/images/Favorites/info2.svg";
import { ReactComponent as Info3Icon } from "../../../../assets/images/Favorites/info3.svg";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  AddRemoveFavorite,
  updateRemoveFromFavorite,
} from "../../../../data/slices/favoriteSlice";
import { useNavigate } from "react-router-dom";
import handleName from "../../../../helpers/handleName";
import { openLinkInNewWindow } from "../../../../helpers/openMediaLink";
import CardTabs from "../CardTabs/CardTabs";
import PriceCard from "../PriceCard/PriceCard";
import ImageNotFound from "../../../../assets/images/image-not-found.jpg";
import ImportantInfo from "../ImportantInfos/ImportantInfo";
import ActionCard from "../actionsCard/ActionCard";
import { Modal } from "antd";
import { useState } from "react";
import RemoveFavoriteModal from "../RemoveFavoriteModal/RemoveFavoriteModal";
const Card = ({
  propertieItem,
  favorite,
  type,
  hideTabs,
  category,
  anotherType,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRemoveFavoritOpen, setIsRemoveFavoritOpen] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";

  const info = [
    {
      icon: <Info1Icon />,
      label: `${t("properties.Area")}  (${
        propertieItem?.specification_array?.unit_area
      })  ${t("properties.m")}`,
      isDisplayed: propertieItem?.specification_array?.unit_area ? true : false,
    },
    {
      icon: <Info2Icon />,
      label: propertieItem?.specification_array?.residential_type,
      isDisplayed: propertieItem?.specification_array?.residential_type
        ? true
        : false,
    },
    {
      icon: <Info3Icon />,
      label: `${t("properties.Total_Rooms")} (${
        propertieItem?.rooms_array?.number_of_rooms
      })`,
      isDisplayed: propertieItem?.rooms_array?.number_of_rooms ? true : false,
    },
  ];

  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleNavigate = () => {
    navigate(`/real-estate/${propertieItem?.id}`, {
      state: { offer_type: propertieItem?.offer_type },
    });
  };
  const handleFavorite = (action) => {
    setIsRemoveFavoritOpen(true);
  };

  const handleDeleteFavoriteFunction = (action) => {
    let msg = "Removed successfully from favorites";

    if (!isAuthenticated) {
      message.info("Please login first");
      navigate("/auth/login");
      return;
    }
    dispatch(
      AddRemoveFavorite({
        body: {
          action: "remove",
          pid: propertieItem?.id,
          offer_type: propertieItem?.offer_type,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res.error === 0) {
          message.success(msg);
          navigate("/favorites");
        } else {
          message.error(res?.message);
        }
      });
  };

  const handleAddFavoriteFunction = (action) => {
    let msg = "Added successfully to favorites";

    if (!isAuthenticated) {
      message.info("Please login first");
      navigate("/auth/login");
      return;
    }
    dispatch(
      AddRemoveFavorite({
        body: {
          action: "add",
          pid: propertieItem?.id,
          offer_type: propertieItem?.offer_type,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res.error === 0) {
          message.success(msg);
          navigate("/favorites");
        } else {
          message.error(res?.message);
        }
      });
  };

  return (
    <div
      className={
        type === "small"
          ? "list-sale-card small "
          : type === "broker"
          ? "list-sale-card broker"
          : "list-sale-card"
      }
    >
      <div className="picture-container">
        <img
          src={propertieItem?.main_image || ImageNotFound}
          alt="pic"
          className="picture"
          width="483"
          onClick={() => openLinkInNewWindow(propertieItem?.media_url)}
        />
        {!favorite ? (
          <FavIcon
            className={currentLanguageCode === "ar" && `ar`}
            onClick={() => handleAddFavoriteFunction()}
          />
        ) : (
          <FavFillIcon
            className={currentLanguageCode === "ar" && `ar`}
            onClick={() => {
              handleFavorite();
              // dispatch(updateRemoveFromFavorite(propertieItem));
            }}
          />
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div>
            <h3 className="title">
              <p className="title-link" onClick={handleNavigate}>
                {propertieItem?.name}
              </p>
            </h3>
            <div className="localisation-container">
              <LocIcon />
              <span>
                {handleName(
                  propertieItem?.address_ar,
                  propertieItem?.address_en,
                  currentLanguageCode
                )}
              </span>
            </div>
          </div>
          {propertieItem?.price_available === "no" ? (
            <div className="no_available_price">{t("no_available_price")}</div>
          ) : (
            <PriceCard propertieItem={propertieItem} />
          )}
        </div>

        <p className="description">
          {propertieItem?.details} {"  "}
          {/* <button>{t("properties.Details")}</button> */}
        </p>

        <ImportantInfo
          importantInfo={propertieItem?.important_info_array}
          property={propertieItem}
        />

        {/* </div> */}

        <div className="tabs-container">
          {hideTabs === undefined || false ? (
            <CardTabs property={propertieItem} type="card" />
          ) : null}
        </div>
        <ActionCard
          type={anotherType}
          category={category}
          hideTabs={hideTabs}
          propertieItem={propertieItem}
        />
      </div>

      <Modal
        closeIcon={false}
        open={isRemoveFavoritOpen}
        onCancel={() => setIsRemoveFavoritOpen(false)}
        centered
        footer={null}
      >
        <RemoveFavoriteModal
          setIsRemoveFavoritOpen={setIsRemoveFavoritOpen}
          handleFavoriteFunction={handleDeleteFavoriteFunction}
        />
      </Modal>
    </div>
  );
};

export default Card;
