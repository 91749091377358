import Location from "./components/Location/Location.jsx";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/Filters/arrow up.svg";
import { useState } from "react";
import Pricing from "./components/Pricing/Pricing.jsx";
import Specifications from "./components/Specifications/Specifications.jsx";
import Rooms from "./components/Rooms/Rooms.jsx";
import Services from "./components/Services/Services.jsx";
import Facilities from "./components/Facilities/Facilities.jsx";
import Fourniture from "./components/Fourniture/Fourniture.jsx";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// import useWindowSize from '../../hooks/useWindowSize';
// import closeIcon from '../../assets/icons/close1.svg';
// import { closeFilter } from '../../data/slices/settingsSlice';
import { useDispatch, useSelector } from "react-redux";
import AuctionFilter from "./components/AuctionFilter/AuctionFilter.jsx";
import AuctionSpecification from "./components/AuctionSpecification/AuctionSpecification.jsx";

import Checkbox from "./components/Inputs/Checkbox/Checkbox";
import Slider from "./components/Inputs/Slider/Slider";
import { handleStateChange } from "../../data/slices/goodsFilterSlice.js";
import DailyRentPrices from "./components/DailyRentPricesFilters/DailyRentPrices.jsx";
import NewDateRange from "../../components/DateRange/NewRangeSelector.jsx";

const GoodsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pricingCollasped, setPriceCollasped] = useState(false);
  const [specificationsCollasped, setSpecificationsCollasped] = useState(false);
  const [roomsCollasped, setRoomsCollasped] = useState(false);
  const [servicesCollasped, setServicesCollasped] = useState(false);
  const [facilitiesCollasped, setFacilitiesCollasped] = useState(false);
  const [fournitureCollasped, setFournitureCollasped] = useState(false);
  // const { width } = useWindowSize();
  const { specifications, default_values, currency_icon } = useSelector(
    (state) => state.goodsList
  );
  
  const filters = useSelector((state) => state.goodsFilters);

  const {
    minprice,
    maxprice,
    price_per_meter_monthly_range,
    price_per_meter_yearly_range,
    price_per_meter,
    toll_price_per_meter_range,
    sell_available,
    toll_price,
    toll_available,
    payment_method,
    daily_price_range,
    daily_total_range,
    smoking_allowed,
    daily_from_date,
    daily_to_date,
  } = useSelector((state) => state.goodsFilters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  console.log(default_values)

  return (
    <div className="filter-container">
      {/* {width > 1255 ? (
        <h3 className='title'>{t('filters.Filter_Search')}</h3>
      ) : (
        <img
          className='close_icon'
          onClick={() => dispatch(closeFilter())}
          src={closeIcon}
          alt=''
        />
      )} */}

      {/* Location filters */}
      <Location />
      {filters.offer_type === "auction" && <AuctionFilter />}
      {filters.offer_type != "auction" && (
        <>
          <button
            className="collapse-btn"
            onClick={() => setPriceCollasped(!pricingCollasped)}
          >
            {t("filters.Pricing_filters")}
            <ArrowUpIcon className={pricingCollasped ? "flip-arrow" : ""} />
          </button>
          {!pricingCollasped ? (
            <div className="filters-content-container">
              <div className="pricing-filters">
                {filters?.offer_type === "sell" ? (
                  <>
                    <p className="label">{t("filters.Selling_Price")}</p>
                    <div className="price-determined-container">
                      <Checkbox
                        label={t("filters.Not_Determined")}
                        checked={sell_available === "no" ? true : false}
                        onClick={() => handleBtnClick("sell_available", "no")}
                      />
                      <Checkbox
                        label={t("filters.Determined_price")}
                        checked={sell_available === "yes" ? true : false}
                        onClick={() => handleBtnClick("sell_available", "yes")}
                      />
                    </div>

                    <Slider
                      min={default_values?.sell_price?.[0] ?? 0}
                      max={default_values?.sell_price?.[1] ?? 0}
                      values={[minprice, maxprice]}
                      target={["minprice", "maxprice"]}
                      unit={currency_icon}
                    />

                    <p className="label">{t("filters.Per_meter")}</p>
                    <Slider
                      min={default_values?.price_per_meter?.[0] ?? 0}
                      max={default_values?.price_per_meter?.[1] ?? 0}
                      values={[
                        price_per_meter?.split("-")[0],
                        price_per_meter?.split("-")[1],
                      ]}
                      target="price_per_meter_range"
                      unit={currency_icon}
                    />

                    <hr />

                    <p className="label">{t("filters.Toll_Price")} </p>
                    <div className="price-determined-container">
                      <Checkbox
                        label={t("filters.Not_available")}
                        checked={toll_available === "no" ? true : false}
                        onClick={() => handleBtnClick("toll_available", "no")}
                      />
                      <Checkbox
                        label={t("filters.Available")}
                        checked={toll_available === "yes" ? true : false}
                        onClick={() => handleBtnClick("toll_available", "yes")}
                      />
                    </div>

                    <Slider
                      min={default_values?.toll_price?.[0] ?? 0}
                      max={default_values?.toll_price?.[1] ?? 0}
                      values={[
                        toll_price?.split("-")[0],
                        toll_price?.split("-")[1],
                      ]}
                      target="toll_price"
                      unit={currency_icon}
                    />

                    <p className="label">{t("filters.Per_meter")}</p>
                    <Slider
                      min={default_values?.toll_price_per_meter?.[0] ?? 0}
                      max={default_values?.toll_price_per_meter?.[1] ?? 0}
                      values={[
                        toll_price_per_meter_range?.split("-")[0],
                        toll_price_per_meter_range?.split("-")[1],
                      ]}
                      target="toll_price_per_meter_range"
                      unit={currency_icon}
                    />
                  </>
                ) : (
                  filters?.offer_type === "rent" && (
                    <>
                      <p className="label">{t("filters.Monthly rent")}</p>
                      <Slider
                        min={default_values?.price_per_meter_monthly?.[0] ?? 0}
                        max={default_values?.price_per_meter_monthly?.[1] ?? 0}
                        values={[
                          price_per_meter_monthly_range?.split("-")[0],
                          price_per_meter_monthly_range?.split("-")[1],
                        ]}
                        target="price_per_meter_monthly_range"
                        unit={currency_icon}
                      />

                      <p className="label">{t("filters.Yearly rent")}</p>
                      <Slider
                        min={default_values?.price_per_meter_yearly?.[0] ?? 0}
                        max={default_values?.price_per_meter_yearly?.[1] ?? 0}
                        values={[
                          price_per_meter_yearly_range?.split("-")[0],
                          price_per_meter_yearly_range?.split("-")[1],
                        ]}
                        target="price_per_meter_yearly_range"
                        unit={currency_icon}
                      />

                      <p className="label">{t("filters.Payment method")}</p>
                      <div className="checkbox-container">
                        <Checkbox
                          label={t("filters.Yearly")}
                          checked={payment_method === "yearly" ? true : false}
                          onClick={() =>
                            handleBtnClick("payment_method", "yearly")
                          }
                        />
                        <Checkbox
                          label={t("filters.3 Months")}
                          checked={payment_method === "quaterly" ? true : false}
                          onClick={() =>
                            handleBtnClick("payment_method", "quaterly")
                          }
                        />
                      </div>
                      <div className="checkbox-container">
                        <Checkbox
                          label={t("filters.6 Months")}
                          checked={payment_method === "halfly" ? true : false}
                          onClick={() =>
                            handleBtnClick("payment_method", "halfly")
                          }
                        />

                        <Checkbox
                          label={t("filters.Monthly")}
                          checked={payment_method === "monthly" ? true : false}
                          onClick={() =>
                            handleBtnClick("payment_method", "monthly")
                          }
                        />
                      </div>
                    </>
                  )
                )}

                {filters.offer_type === "daily_rent" && (
                  <div>
                    <>
                      <div className="price_daily">
                        <p className="label">
                          {t("daily_rent.price_of_one_day")}
                        </p>
                        <Slider
                          min={default_values?.price_per_daily?.[0] ?? 0}
                          max={default_values?.price_per_daily?.[1] ?? 0}
                          values={[
                            daily_price_range?.split("-")[0],
                            daily_price_range?.split("-")[1],
                          ]}
                          target="daily_price_range"
                          unit={currency_icon}
                        />
                      </div>
                      <div className="price_daily">
                        <p className="label">
                          {t("daily_rent.Total Of All days Price")}
                        </p>
                        <Slider
                          min={default_values?.price_total_daily?.[0] ?? 0}
                          max={default_values?.price_total_daily?.[1] ?? 0}
                          values={[
                            daily_total_range?.split("-")[0],
                            daily_total_range?.split("-")[1],
                          ]}
                          target="daily_total_range"
                          unit={currency_icon}
                        />
                      </div>
                      <div className="checkbox-container">
                        <Checkbox
                          label={t("daily_rent.No_Smoking")}
                          checked={smoking_allowed === "no" ? true : false}
                          onClick={() =>
                            handleBtnClick("smoking_allowed", "no")
                          }
                        />
                        <Checkbox
                          label={t("daily_rent.Smoking_Allowed")}
                          checked={smoking_allowed === "yes" ? true : false}
                          onClick={() =>
                            handleBtnClick("smoking_allowed", "yes")
                          }
                        />
                      </div>
                      <div className="btns-list">
                        <button
                          className={
                            daily_from_date ===
                            dayjs(Date.now()).format("YYYY-MM-DD")
                              ? `btn btn-active`
                              : "btn"
                          }
                          onClick={() => {
                            handleBtnClick(
                              "daily_from_date",
                              dayjs(Date.now()).format("YYYY-MM-DD")
                            );
                            handleBtnClick("daily_to_date", null);
                          }}
                        >
                          {t("daily_rent.Available_today")}
                        </button>
                        {/* <DateRange /> */}
                        <NewDateRange />
                        {/* <p className="num-days">{ nbDays}</p> */}
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default GoodsFilter;
