import { Outlet, useLocation } from "react-router-dom";
import AuthGuard from "../../components/AuthGuard";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import Footer from "../../components/Footer/Footer";
import Filter from "../../components/MobileFilter/Filter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetFilters } from "../../data/slices/brokerFilters";

const MainLayout = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(resetFilters());
  }, [location]);

  return (
    // <AuthGuard>
    <div className="main_layout">
      <Sidebar />
      <Filter />
      <div className="main_layout__container">
        <Header />
        <Outlet />
        <Footer />
      </div>
      <Backdrop />
    </div>
    // </AuthGuard>
  );
};

export default MainLayout;
