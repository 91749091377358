import React, { useEffect, useState } from "react";
import "./_SelectInput.scss";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleStateChange } from "../../../../data/slices/filtersSlice";

function SelectInput() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { offer_type } = useSelector((state) => state.filters);
  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  return (
    <div>
      {offer_type === "daily_rent" ? (
        <Select
          key="1234"
          defaultValue={"available_first"}
          options={[
            {
              value: "available_first",
              label: t("daily_rent.availability"),
            },
            {
              value: "available_last",
              label: t("daily_rent.non_availability"),
            },
          ]}
          className="select_filters"
          onChange={(e) => handleBtnClick("sorting", e)}
        />
      ) : (
        <Select
          key="12346789"
          defaultValue={"most_recent"}
          className="select_filters"
          options={[
            {
              value: "most_recent",
              label: t("properties.Most_recent"),
            },
            {
              value: "oldest",
              label: t("properties.oldest"),
            },
          ]}
          onChange={(e) => handleBtnClick("sorting", e)}
        />
      )}
    </div>
  );
}

export default SelectInput;
