import { Slider as MUISlider } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleStateChange } from "../../../../../data/slices/goodsFilterSlice";
import { useState, useEffect } from "react";

const Slider = ({ min, max, values, unit = "$", target, }) => {
  const dispatch = useDispatch();
  const [animationValues, setAnimationValues] = useState(values);
  const [actualValues, setActualValues] = useState(values);

  if (!actualValues[0]) actualValues[0] = min;
  if (!actualValues[1]) actualValues[1] = max;

  useEffect(()=>{
    setActualValues(values);
    setAnimationValues(values)
  },[values])

  const minDistance = 0;

  function valuetext(value) {
    return `${value}`;
  }

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    setAnimationValues(newValue); // Update animation immediately

    if (typeof target === "string") {
      if (activeThumb === 0) {
        setActualValues({
          target: target,
          value: String(newValue[0] + "-" + actualValues[1]),
        });
      } else {
        setActualValues({
          target: target,
          value: String(actualValues[0] + "-" + newValue[1]),
        });
      }
    } else {
      if (activeThumb === 0) {
        setActualValues({
          target: target[0],
          value: String(Math.min(newValue[0], actualValues[1] - minDistance)),
        });
      } else {
        setActualValues({
          target: target[1],
          value: String(Math.max(newValue[1], actualValues[0] + minDistance)),
        });
      }
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(handleStateChange(actualValues));
  //   }, 500); // You can adjust the delay (500 milliseconds in this example)
  //   return () => clearTimeout(timer);
  // }, [actualValues]);
  const handleSlideEnd = () => {
    // Dispatch the state change when user finishes sliding
    dispatch(handleStateChange(actualValues));
  };

  return (
    <div className="slider-input">
      <MUISlider
        value={animationValues}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
        min={min}
        max={max}
        onChange={handleChange}
        onChangeCommitted={handleSlideEnd}
      />
      <div className="slider-values-container">
        <span>
           
            <div dangerouslySetInnerHTML={{ __html: `${unit}` }} />

          {new Intl.NumberFormat().format(actualValues[0])}
        </span>
        <span>
        <div dangerouslySetInnerHTML={{ __html: `${unit}` }} />

          {new Intl.NumberFormat().format(actualValues[1])}
        </span>
      </div>
    </div>
  );
};

export default Slider;
