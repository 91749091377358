import React, { useMemo, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import Window from "./components/WindowMap/Window";
import useWindowSize from "../../../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { openModal } from "../../../data/slices/modals";

const Map = ({ properties, anotherType, category }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [selectedPlace, setSelectedPlace] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = useMemo(
    () => ({
      lat:
        Number(properties?.[0]?.lat_long?.split(",")[0].trim()) ||
        35.83426185090107,
      lng:
        Number(properties?.[0]?.lat_long?.split(",")[1].trim()) ||
        10.607890187419523,
    }),
    []
  );

  if (!isLoaded) return <Spin />;

  return (
    <div style={{ height: "100vh", width: "100%" }} className="principal_map">
      <GoogleMap zoom={12} center={center} mapContainerStyle={containerStyle}>
        {properties?.map((item, index) => {
          return (
            <MarkerF
              key={index}
              onClick={() => {
                if (width > 600) {
                  item === selectedPlace
                    ? setSelectedPlace(undefined)
                    : setSelectedPlace(item);
                } else {
                  dispatch(
                    openModal("property-card-modal", { property: item })
                  );
                }
              }}
              position={{
                lat: Number(item?.lat_long?.split(",")[0].trim()),
                lng: Number(item?.lat_long?.split(",")[1].trim()),
              }}
            />
          );
        })}
        {selectedPlace && <Window anotherType={anotherType} category={category} selectedPlace={selectedPlace} />}
      </GoogleMap>
    </div>
  );
};

export default Map;
