import React from "react";
import "./PriceCard.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

function PriceCard({ propertieItem }) {
  const { t } = useTranslation("translation");

  const { auction_type, auction_category } = useSelector(
    (state) => state.filters
  );

  return (
    <div className="data-container price">
      {propertieItem?.offer_type === "sell" && (
        <>
          <span className="data-title">
            {propertieItem?.price?.sell?.price} {propertieItem?.currency_key}
          </span>
          <span className="data-subtitle">
            {propertieItem?.price?.sell?.price_per_meter}{" "}
            {propertieItem?.currency_key}
            {t("properties.RS_meter")}
          </span>
        </>
      )}

      {propertieItem?.offer_type === "auction" && (
        <>
          <Tooltip
            color="white"
            className="tool-tip-price"
            title={
              <p className="tooltip-color">
                {auction_category === "current"
                  ? t("property_details_auction.tooltips.price_curr")
                  : auction_category === "future"
                  ?  t("property_details_auction.tooltips.price_future")
                  :  t("property_details_auction.tooltips.price_ended")}
              </p>
            }
          >
            <span className="data-title">
              {propertieItem.auction?.ended_status
                ? propertieItem?.auction?.sell_price
                : propertieItem?.auction?.last_toll_price > 0
                ? propertieItem?.auction?.last_toll_price
                : propertieItem?.auction?.bidding_start_price}{" "}
              {propertieItem?.currency_key}
            </span>
          </Tooltip>
          <Tooltip
            color="white"
            className="tool-tip-price"
            title={
              <p className="tooltip-color">
                {auction_category === "current"
                  ? t("property_details_auction.tooltips.price_curr")
                  : auction_category === "future"
                  ?  t("property_details_auction.tooltips.price_future")
                  :  t("property_details_auction.tooltips.price_ended")}
              </p>
            }
          >
            <span className="data-subtitle">
              {auction_category === "current"
                ? propertieItem?.auction.last_toll_price_per_meter
                : auction_category === "future"
                ? propertieItem?.auction.bidding_start_price_per_meter
                : auction_category === "ended"
                ? propertieItem?.auction.sell_price_per_meter
                : propertieItem?.price?.sell?.price_per_meter}{" "}
              {propertieItem?.currency_key}
              {t("properties.RS_meter")}
            </span>
          </Tooltip>
        </>
      )}

      {propertieItem?.offer_type === "rent" && (
        <>
          {propertieItem?.price?.monthly?.price ? (
            <>
              {" "}
              <span className="data-title">
                {propertieItem?.price?.monthly?.price}{" "}
                {propertieItem?.currency_key}
              </span>
              <span className="data-per-year">
                {propertieItem?.price?.yearly?.price}{" "}
                {propertieItem?.currency_key} {t("per Year")}
              </span>{" "}
            </>
          ) : (
            <span className="no-available-title">
              {t("no_available_price")}
            </span>
          )}
        </>
      )}
      {propertieItem?.offer_type === "daily_rent" && (
        <>
          <span
            className={
              propertieItem?.available
                ? "data-title"
                : "data-title not_available"
            }
          >
            {propertieItem?.available
              ? t("daily_rent.availability")
              : t("daily_rent.non_availability")}
          </span>
          {propertieItem?.available &&
          propertieItem?.goods_default_price != 0 &&
          propertieItem?.goods_default_price &&
          propertieItem?.goods_default_price != "" ? (
            <span className="data-per-year">
              {propertieItem?.goods_default_price}{" "}
              {propertieItem?.currency_key}
            </span>
          ) : (
            <span className="no-available-title">
              {t("no_available_price")}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default PriceCard;
