import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import "./AuctionModal.css"
import logoAuction from "../../assets/icons/logoAuction.png"
import { Button } from "antd";
import { t } from "i18next";

function ActionModal({ open, id, propertieItem, handleClose }) {
   
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogContent className="ta-modal-content-scroll auction-start-modals">
        <img src={logoAuction} alt="" />
         <h4>
         {t("property_details_auction.modal.title")}
         </h4>
         <p>{t("property_details_auction.modal.sub")}</p>
        <div className="auction-buttons">
            <Button target="_blank" href={"https://"+propertieItem?.auction?.auction_site_url} className="go-to-site  green-bg" >
            {t("property_details_auction.modal.visit")}
            </Button>
            <Button className="go-back  " onClick={() => handleClose(id)}>
                {t("property_details_auction.modal.back")}            
            </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ActionModal;
