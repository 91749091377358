import React, { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { AddRemoveFavorite } from "../../../../data/slices/favoriteSlice";
import { getFavorite } from "../../../../data/slices/favoriteSlice";
import { updateRemoveFromFavorite } from "../../../../data/slices/favoriteSlice";

const RemoveFromFavorite = () => {
  const { t } = useTranslation();
  const { direction } = useSelector((state) => state.settings);
  const { propertieItem } = useSelector((state) => state.properties);
  const { removeFromFavorite } = useSelector((state) => state.favorite);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    if (removeFromFavorite !== null && removeFromFavorite !== undefined) {
      setVisible(true);
    }

  }, [removeFromFavorite]);

  if (removeFromFavorite === null) return null;
  return (
    <Modal centered open={visible} closeIcon={null} footer={null}>
      <div className="remove-favorite-modal">
        <h3>{t("favorite.Remove_from_favorite")}</h3>
        <p>{t("favorite.remove_from_favorite_body")}</p>
        <div className="buttons">
          <button
            onClick={() => {
              dispatch(
                AddRemoveFavorite({
                  body: {
                    action: "remove",
                    pid: removeFromFavorite?.id,
                  },
                })
              )
                .unwrap()
                .then(() => {
                  dispatch(
                    getFavorite({ lang: direction === "ltr" ? "en" : "ar" })
                  );
                  dispatch(updateRemoveFromFavorite(null));
                  setVisible(false);
                });
              message.open({
                type: "success",
                content: "Removed successfully from favorites",
              });
            }}
            className="remove"
          >
            {t("favorite.Remove")}
          </button>
          <button
            onClick={() => {
              dispatch(updateRemoveFromFavorite(null));
              setVisible(false);
            }}
            className="cancel"
          >
            {t("favorite.Cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveFromFavorite;
