import React, { useEffect, useState } from "react";
import { DatePicker as AntDatePicker, Space, Button } from "antd";
import "dayjs/locale/ar"; // Import the Arabic locale for dayjs
import locale_ar from "antd/es/date-picker/locale/ar_EG";
import locale_en from "antd/es/date-picker/locale/en_US";
import "./NewRangeAntd.scss";
import dayjs from "dayjs";
import { CalendarOutlined } from "@ant-design/icons";
import { handleStateChange } from "../../data/slices/filtersSlice";
import { handleStateChange as handleStateChangeOfBroker } from "../../data/slices/brokerFilters";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { ConfigProvider } from "antd";

const NewDateRange = ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next") || "en";

  const [selectedRange, setSelectedRange] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [datePickerKey, setDatePickerKey] = useState(0); // Add key state

  const { daily_from_date, daily_to_date } = useSelector(
    (state) => state.filters
  );

  // when broker
  const {
    daily_from_date: daily_from_date_broker,
    daily_to_date: daily_to_date_broker,
  } = useSelector((state) => state.brokerFilters);

  const handleBtnClick = (target, value) => {
    if (type === "broker_list") {
      dispatch(handleStateChangeOfBroker({ target, value }));
    } else {
      dispatch(
        handleStateChange({
          target,
          value,
        })
      );
    }
  };

  const handleButtonClick = (e) => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const handleDropdownClick = () => {
    // Your function to handle the click inside the dropdown
  
    setDatePickerKey((prevKey) => prevKey + 1);
  };

  const handleConfirm = () => {
    onConfirm();
    setIsDatePickerOpen(false);
  };
  const handleCancel = () => {
    setIsDatePickerOpen(true);
    setSelectedRange([]);
  };

  const renderExtraFooter = () => {
    const [startDate, endDate] = selectedRange?.map((date) =>
      date ? date.format("YYYY/MM/DD") : ""
    );

    return (
      <div className="footer_picker">
        <div className="actions" dir={currentLanguage === "ar" ? "rtl" : "ltr"}>
          <Button onClick={handleConfirm} type="primary">
            {t("daily_rent.Confirm")}
          </Button>
          <Button
            className="reset_picker"
            onClick={handleCancel}
            type="primary"
          >
            {t("daily_rent.Reset")}
          </Button>
        </div>
        <div className="selected">
          {startDate && endDate && (
            <p>
              {startDate} - {endDate}
            </p>
          )}
        </div>
      </div>
    );
  };
  const handleDateString = (nb) => {
    //needs some refactoring
    
    if (nb > 1)
      return (
        <>
          <span>{nb}</span> {t("daily_rent.Days")}
        </>
      );
    if (nb === 0) return null;
    if (!nb) return "";
    return (
      <>
        <span>{nb}</span> {t("daily_rent.Day")}
      </>
    );
  };
  function calculateDateDifference(dates) {
    if (dates.length === 0) {
      return 0;
    }
    if (dates.length === 1) {
      return 1;
    }

    const startDate = dayjs(dates[0]);
    const endDate = dayjs(dates[1]);
    if (!startDate.isValid() || !endDate.isValid()) {
      return 0; // Handle invalid dates
    }
    const differenceInDays = endDate?.diff(startDate, "day");

    return differenceInDays + 1;
  }

  const onConfirm = () => {
    if (selectedRange[0] && selectedRange[1]) {
      handleBtnClick(
        "daily_from_date",
        dayjs(selectedRange[0]).format("YYYY-MM-DD")
      );
      handleBtnClick(
        "daily_to_date",
        dayjs(selectedRange[1]).format("YYYY-MM-DD")
      );
    }
  };
  const handleClassName = () => {
    if (type === "broker_list") {
      return daily_from_date_broker && daily_to_date_broker
        ? `btn calendar-btn btn-active`
        : "btn calendar-btn";
    } else {
      return daily_from_date && daily_to_date
        ? `btn calendar-btn btn-active`
        : "btn calendar-btn";
    }
  };
  return (
    <ConfigProvider direction={currentLanguage === "ar" ? "rtl" : "ltr"}>
      <div className="range_cal">
        <Space direction="vertical" className="calendar-container">
          <button className={handleClassName()} onClick={handleButtonClick}>
            {t("daily_rent.Available_of_days")} <CalendarOutlined />
          </button>
          <AntDatePicker.RangePicker
            style={{ visibility: "hidden", width: 0, direction: "rtl" }}
            value={selectedRange}
            onChange={(v) => {
              setSelectedRange(v.map((date) => dayjs(date)));
              // setDatePickerKey((prevKey) => prevKey + 1); // Increment the key to force re-render
            }}
            format="YYYY-MM-DD"
            locale={currentLanguage === "ar" ? locale_ar : locale_en}
            showToday={false}
            open={isDatePickerOpen}
            // onOpenChange={(status) => setIsDatePickerOpen(status)}
            renderExtraFooter={renderExtraFooter}
            key={datePickerKey}
            onOpenChange={(status) => {
              setIsDatePickerOpen(status);
              if (status) {
                handleDropdownClick();
              }
            }}
            popupClassName={
              currentLanguage === "ar"
                ? " range_picker range_picker_ar"
                : "range_picker range_picker_en"
            }
          />
        </Space>
        {(daily_from_date || daily_from_date_broker) && (
          <p className="selected_days_length" style={{ marginTop: "-30px" }}>
            {/* {handleDateString(calculateDateDifference([...selectedRange]))} */}

            {type === "broker_list"
              ? handleDateString(
                  calculateDateDifference([
                    daily_from_date_broker,
                    daily_to_date_broker,
                  ])
                )
              : handleDateString(
                  calculateDateDifference([daily_from_date, daily_to_date])
                )}
          </p>
        )}
      </div>
    </ConfigProvider>
  );
};

export default NewDateRange;
