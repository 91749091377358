import React from "react";
import logo from "../../../../assets/images/ManagementSaleOrg/logo.png";
import phone from "../../../../assets/images/ManagementSaleOrg/phone.svg";
import whts from "../../../../assets/images/ManagementSaleOrg/whats.svg";
import "./_CallSection.scss";
function CallSection({ property }) {
  
  return (
    <div>
      <div className="logo_section">
        {property?.user_details?.selling_agent?.image && (
          <img
            src={property?.user_details?.selling_agent?.image}
            className="logo_img"
            alt=""
          />
        )}
        <div className="call">
          <a
            href={`https://api.whatsapp.com/send?phone=${property?.whatsapp}`}
            className="btn_call"
          >
            <img src={whts} alt="" />
          </a>
          <a
            href={`tel:${property?.phone}`}
            target="_blank"
            className="btn_call"
          >
            <img src={phone} alt="Phone" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CallSection;
